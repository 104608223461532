import Navbar from "../../components/Navbar";
import Cards from "./Cards";
import { english } from "../../data/language/English";
import { SuperSEO } from "react-super-seo";

const Decks = () => {
  return (
    <div>
      <SuperSEO
        title="Anki Decks - Collection of decks to learn english."
        lang={english.SEO.lang}
        description={english.SEO.description}
      >
        <link rel="canonical" href="https://www.anki.com.br/decks" />
        <meta name="description" content={english.SEO.description} />
      </SuperSEO>
      <Navbar navbar={english.navbar} />
      <Cards />
    </div>
  );
};

export default Decks;
