export const section3 = {
  en: {
    about: [
      "About Anki",
      "Anki is a program which makes remembering things easy. Because it's a lot more efficient than traditional study methods, you can either greatly decrease your time spent studying, or greatly increase the amount you learn.",
      "Anyone who needs to remember things in their daily life can benefit from Anki. Since it is content-agnostic and supports images, audio, videos and scientific markup (via LaTeX), the possibilities are endless. For example:",
      "Learning a language",
      "Studying for medical and law exams",
      "Memorizing people's names and faces",
      "Brushing up on geography",
      "Mastering long poems",
      "Even practicing guitar chords!",
    ],
    features: {
      title: "Features",
      itemsSpan: [
        "Synchronization",
        "Flexibility",
        "Media-Rich",
        "Optimized",
        "Fully Extensible",
        "Open Source",
      ],
      items: [
        "Use the free AnkiWeb synchronization service to keep your cards in sync across multiple devices.",
        "From card layout to review timing, Anki has a wealth of options for you to customize.",
        "Embed audio clips, images, videos and scientific markup on your cards, with precise control over how it's shown.",
        "Anki will handle decks of 100,000+ cards with no problems.",
        "There are a large number of add-ons available.",
        "Because the code and storage format is open, your important data is safe.",
      ],
    },
    block: {
      items: [
        '"The single biggest change that Anki brings about is that it means memory is no longer a haphazard event, to be left to chance. Rather, it guarantees I will remember something, with minimal effort. That is,"Anki makes memory a choice."',
        "Anki makes memory a choice.",
        'Michael A. Nielsen, "Augmenting Long-term Memory"',
        "No other application [...] comes remotely close to Anki in terms of the number and power of features, flexibility in study, or implementation of spaced repetition.",
        '"K. M. Lawson, "Anki All the Way"',
        "\"I've been using Anki for two years now, and I just wanted to thank you personally for contributing to the single most obvious improvement in my quality of life. I'm not being hyperbolic: consistent use of Anki has opened more doors for me intellectually than I could have imagined two years ago. And being a poor student, I'll be forever grateful that you've provided this software open-source and free of charge.\"",
        "Keldin, via email",
      ],
      links: [
        "http://augmentingcognition.com/ltm.html",
        "http://foolsworkshop.com/reviews/index.html%3Fp=124.html",
        "/",
      ],
    },
  },
  pt: {
    about: [
      "Anki - Sistema de Repetição Espaçada",
      "Anki é um programa que auxilia a lembrar/estudar qualquer coisa mais facilmente. Já que é muito mais eficiente que os métodos tradicionais de estudo, você poderá diminuir muito o tempo gasto estudando ou aumentar muito a qualidade do que você aprende.",
      "Qualquer um que precise se lembrar de alguma coisa em sua vida diária pode se beneficiar deste aplicativo. Como independe de conteúdo e suporta imagens, áudio, vídeos e marcação científica (via LaTeX ou MathJax), as possibilidades são infinitas. Por exemplo:",
      "Aprenda Idiomas;",
      "Estude para Medicina, OAB, Enem;",
      "Estude para Vestibulares;",
      "Estude para Concursos;",
      "Estude Códigos de Programação;",
      "Até mesmo prátique Acordes Músicais!",
    ],
    features: {
      title: "Características",
      itemsSpan: [
        "Sincronização",
        "Flexibilidade",
        "Multimídia",
        "Otimizado",
        "Totalmente Extensível",
        "Código Aberto",
      ],
      items: [
        "Use o serviço de sincronização gratuito da AnkiWeb para manter seus cartões sincronizados em vários dispositivos.",
        "Desde o layout do cartão até o tempo de revisão, o Anki tem uma grande variedade de opções para você personalizar.",
        "Incorpore áudios, imagens, vídeos e marcação científica em seus cartões, com controle preciso sobre como eles são mostrados.",
        "O Anki irá gerir decks de 100.000+ cartões sem problemas.",
        "Existem uma grande quantidade de add-ons (complementos) disponíveis.",
        "Como o código é aberto e o formato de armazenamento é gratuito, seus dados importantes estão seguros.",
      ],
    },
    block: {
      items: [
        '"A grande mudança que o Anki traz é que, agora, memorizar não é mais um evento aleatório para ser deixado ao acaso. Em vez disso, garante que me lembrarei de algo, com um esforço mínimo. Isso é',
        "Anki faz da memória uma escolha.",
        'Michael A. Nielsen, "Augmenting Long-term Memory"',
        '"Eu tenho usado o Anki há dois anos, e eu só queria agradecer você pessoalmente por contribuir para a melhoria mais óbvia na minha qualidade de vida. Eu não estou sendo hiperbólico: uso consistente do dele abriu mais portas para mim intelectualmente do que eu poderia ter imaginado há dois anos. E sendo um estudante pobre, estarei para sempre grato a você que forneceu este software de código aberto e livre de custos."',
        "Keldin, via email",
        '"Nenhum outro aplicativo chega remotamente próximo ao Anki em termos de número e poder de recursos, flexibilidade no estudo ou implementação de repetição espaçada. Ele é eficiente para concursos, vestibulares, idiomas."',
        "Breno Gonzaga",
      ],
      links: ["http://augmentingcognition.com/ltm.html", "/", "https://www.brenogonzaga.com/"],
    },
  },
};
