import styles from "./HomeSection1.module.css";

type Props = {
  home: string[];
};

const HomeSection1 = ({ home }: Props) => {
  const goToByScroll = (e: React.MouseEvent) => {
    //remove id from url
    e.preventDefault();
    const id = e.currentTarget.getAttribute("href")?.replace("#", "");
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.container}>
      <p>{home[0]}</p>
      <span className="hidden-phone">{home[1]}</span>
      <a className="btn btn-primary" onClick={goToByScroll} href="#download">
        {home[2]}
      </a>
    </section>
  );
};

export default HomeSection1;
