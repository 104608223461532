import styles from "./Cards.module.css";
import { useState } from "react";
// page decks - show each card
import Card from "../../pages/decks/Card";
import Footer from "../../components/Footer";
import { dataDecks } from "../../data/decks/Decks";
import { english } from "../../data/language/English";

const Cards = () => {
  const [count, setCount] = useState(10);

  const showMoreDecks = () => {
    setCount(count + 5);
  };

  return (
    <div className={styles.container}>
      {dataDecks.decks.map((deck: any) =>
        deck.id < count ? (
          <div key={deck.id} className={styles.card}>
            {deck && <Card key={deck.id} deck={deck} />}
          </div>
        ) : null
      )}
      <div className={styles.buttonContainer}>
        {dataDecks.decks.length > count && (
          <button className="btn-data" onClick={showMoreDecks}>
            Show more decks
          </button>
        )}
      </div>
      <Footer footer={english.footer} />
    </div>
  );
};

export default Cards;
