// home page sections
import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
// components
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// interface
import { IHome } from "../../Interfaces/Interfaces";
import { SuperSEO } from "react-super-seo";

const Home = (language: IHome) => {
  const { SEO, navbar, home, footer } = language;

  return (
    <>
      <SuperSEO title={SEO.title} description={SEO.description} lang={SEO.lang}>
        <meta name="description" content={SEO.description} />
      </SuperSEO>
      <Navbar navbar={navbar} />
      <HomeSection1 home={home.home} />
      <HomeSection2 homeRemember={home.homeRemember} />
      <HomeSection3 about={home.homeText} features={home.homeFeatures} block={home.homeBlock} />
      <HomeSection4
        windows={home.homeDownload.windows}
        mac={home.homeDownload.mac}
        linux={home.homeDownload.linux}
        mobile={home.homeDownload.mobile}
        dev={home.homeDownload.dev}
      />
      <Footer footer={footer} />
    </>
  );
};

export default Home;
