import styles from "./Cards.module.css";

type Props = {
  deck: {
    id: number;
    title: string;
    description: string;
    imgs: string[];
    moreImgs: string[];
    notes: number;
    url: string;
    download: string;
  };
};

const Card = ({ deck }: Props) => {
  return (
    <div>
      <h2 className={styles.container}>
        {deck.id}. {deck.title}
      </h2>
      <p>{deck.description}</p>
      <div className={styles.image}>
        {deck.imgs.map((img) => (
          <img loading="lazy" key={img} src={img} alt="img" />
        ))}
      </div>
      <span>This deck has approximately {deck.notes} notes.</span>
      <div className={styles.btn}>
        <a href={`/decks/${deck.url}`}>Show details</a>
        <a href={deck.download}>Download</a>
      </div>
    </div>
  );
};

export default Card;
