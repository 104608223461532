export const section2 = {
  en: [
    "Remember Anything",
    "From images to scientific markup, Anki has got you covered.",
    "Remember Anywhere",
    "Review on Windows, Mac, Linux, iOS, Android, and any device with a web browser.",
    "Remember Efficiently",
    "Only practice the material that you're about to forget.",
    "AnkiWeb",
    "This site is just an alternative link. The official website is",
  ],
  pt: [
    "Lembre Qualquer Coisa",
    "De imagens à fórmulas científicas, Anki tem tudo o que você precisa.",
    "Lembre Onde Estiver",
    "Estude no Windows, Mac, Linux, iOS, Android, em qualquer dispositivo com um navegador web.",
    "Lembre Eficientemente",
    "Apenas pratique aquilo que você está prestes a esquecer.",
    "AnkiWeb",
    "Este site é apenas um link alternativo. O site oficial é",
  ],
};
