export const dev = {
  en: {
    title: ["Source code", "Development", "Translating Anki"],
    items: [
      "Anki's source is available on GitHub. There are instructions for building in the docs/ folder. If you encounter problems with the build system please let us know, but please note the expectation is that you are able to dig into basic issues by yourself. If you have no programming experience, please use the packaged version instead.",
      "If you encounter bugs while running Anki from source, please check to see if you can reproduce them in the packaged version, as the Qt version or other libraries on your system can be the cause of some problems.",
      "The latest code is located on",
      "Please see the files in docs/ for further instructions.",
      "Please see",
      "for info on translating Anki into a different language.",
    ],
    links: {
      name: ["Source tarballs", "Other old downloads", "GitHub", "this page"],
      link: [
        "https://github.com/ankitects/anki/releases",
        "https://apps.ankiweb.net/downloads/archive/",
        "https://github.com/ankitects/anki",
        "https://translating.ankiweb.net/",
      ],
    },
  },
  pt: {
    title: ["Código Fonte", "Desenvolvimento", "Traduzindo Anki"],
    items: [
      "O código fonte do Anki está disponível no GitHub. Existem instruções para construir na pasta docs/. Se você encontrar problemas com o sistema de compilação, informe-nos, mas observe que a expectativa é que você seja capaz de resolver os problemas básicos sozinho. Se você não tem experiência em programação, use a versão compilada.",
      "Se você encontrar bugs ao executar o Anki a partir do código fonte, verifique se você pode reproduzi-los na versão compilada, pois a versão de Qt ou outras bibliotecas em seu sistema podem ser a causa de alguns problemas.",
      "O código mais recente está localizado em",
      "Consulte os arquivos em docs/ para obter mais instruções.",
      "Consulte",
      "para obter informações sobre como traduzir o Anki para um idioma diferente.",
    ],
    links: {
      name: ["Tarballs de código", "Outros downloads antigos", "GitHub", "esta página"],
      link: [
        "https://github.com/ankitects/anki/releases",
        "https://apps.ankiweb.net/downloads/archive/",
        "https://github.com/ankitects/anki",
        "https://translating.ankiweb.net/",
      ],
    },
  },
};
