export const footer = {
  en: {
    name: ["Home", "Decks", "Support", "AnkiWeb"],
    link: ["/english", "/decks", "https://faqs.ankiweb.net/", "https://ankiweb.net/"],
    text: ["Shared decks?", "Need help?"],
    button: ["Download", "Go to Forums"],
  },
  pt: {
    name: ["Início", "Decks", "Blog", "AnkiWeb"],
    link: ["/", "/decks", "/blog", "/web"],
    text: ["Decks compartilhados?", "Precisa de ajuda?"],
    button: ["Download", "Ir ao Fórum"],
  },
};
