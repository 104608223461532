import { section1 } from "./sections/Section1";
import { section2 } from "./sections/Section2";
import { section3 } from "./sections/Section3";
import { section4 } from "./sections/Section4";

export const home = {
  en: {
    section1: section1.en,
    section2: section2.en,
    section3: section3.en,
    section4: section4.en,
  },
  pt: {
    section1: section1.pt,
    section2: section2.pt,
    section3: section3.pt,
    section4: section4.pt,
  },
};
