export const navbar = {
  en: {
    name: ["Home", "Decks", "Support", "AnkiWeb"],
    link: ["/english", "/decks", "https://faqs.ankiweb.net/", "https://ankiweb.net/"],
  },
  pt: {
    name: ["Início", "Decks", "Blog", "AnkiWeb"],
    link: ["/", "/decks", "/blog", "/web"],
  },
};
