import { footer } from "./footer/Footer";
import { home } from "./home/Home";
import { navbar } from "./navbar/Navbar";

export const portuguese = {
  SEO: {
    lang: "pt-BR",
    title: "Anki- Sistema de Repetição Espaçada",
    description:
      "Anki é um poderoso programa de flashcards usado para memorização, aprender idiomas, estudar para concursos, vestibulares e muito mais.",
  },
  navbar: navbar.pt,
  home: {
    home: home.pt.section1,
    homeRemember: home.pt.section2,
    homeText: home.pt.section3.about,
    homeFeatures: home.pt.section3.features,
    homeBlock: home.pt.section3.block,
    homeDownload: home.pt.section4,
  },
  footer: footer.pt,
};
