import { dev } from "./Section4Dev";
import { linux } from "./Section4Linux";
import { mac } from "./Section4Mac";
import { mobile } from "./Section4Mobile";
import { windows } from "./Section4Windows";

export const section4 = {
  en: {
    windows: windows.en,
    mac: mac.en,
    linux: linux.en,
    mobile: mobile.en,
    dev: dev.en,
  },
  pt: {
    windows: windows.pt,
    mac: mac.pt,
    linux: linux.pt,
    mobile: mobile.pt,
    dev: dev.pt,
  },
};
