export const mac = {
  en: {
    download: {
      title: 'Download',
      items: ['2.1.63 was the previous release. Two variants are available:', '2.1.64 was just released.'],
    },
    links: {
      name: [
        'Download Anki for macOS 10.14.4+ (Intel, 2.1.63 Qt6)',
        'Download Anki for macOS (Apple Silicon, 2.1.63 Qt6)',
        'Download Anki for macOS 10.13.4+ (Intel, 2.1.63 Qt5)',
        'Download Anki for macOS 10.14.4+ (Intel, 2.1.64 Qt6)',
        'Download Anki for macOS (Apple Silicon, 2.1.64 Qt6)',
        'Download Anki for macOS 10.13.4+ (Intel, 2.1.64 Qt5)',
        'Installation & troubleshooting guide',
        'Change notes & older releases',
        'Alternate download site',
        'Alternate changes site',
      ],
      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-intel-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-apple-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-intel-qt5.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-intel-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-apple-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-intel-qt5.dmg',
        'https://docs.ankiweb.net/platform/mac/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requirements',
      items: [
        'Recent Anki releases require a Mac running macOS 10.13.4 or later.',
        "The last Anki release that supported macOS 10.10 to 10.13.3 was 2.1.35-alternate. If you're on an old machine, you can obtain the old version from the",
      ],
      links: ['releases page'],
    },
    installing: {
      title: 'Installating',
      items: [
        'To install Anki:',
        'Download Anki from',
        'See the next section for how to choose between -qt5 and -qt6.',
        'Save the file to your desktop or downloads folder.',
        'Open it, and drag Anki to your Applications folder or desktop.',
        'Double-click on Anki in the location you placed it.',
      ],
      links: [],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'Recent Anki versions come in separate Qt5 and Qt6 variants. The Qt6 version is recommended for most users.',
        'Advantages of the Qt6 version:',
        'Native support for recent Apple Silicon Macs (faster, better battery life).',
        'Various bugfixes, including things like better support for less common languages.',
        'Security updates. Support for the Qt5 library was discontinued in Nov 2020, meaning that any security flaws discovered since then will remain unfixed.',
        'Disadvantages of the Qt6 version:',
        'Tabbed windows (eg in full screen) are no longer possible.',
        'Some add-ons currently only work with the Qt5 version.',
      ],
      links: [],
    },
    upgrading: {
      title: 'Upgrading',
      items: [
        'To upgrade, close Anki if it is open, and then follow the steps above. Drag the Anki icon into the same location you previously stored it, and when prompted, overwrite the old version. Your card data will be preserved.',
      ],
      links: [],
    },
    addon: {
      title: 'Add-on Compatibility',
      items: [
        'Some add-ons have not been updated to support the latest Anki release yet. If an add-on you depend on has not been ported to the latest release yet, you may have more luck with 2.1.44 from the',
      ],
      links: ['releases page'],
    },
  },
  pt: {
    download: {
      title: 'Download',
      items: [
        '2.1.63 foi a versão anterior. Duas variantes estão disponíveis:',
        '2.1.64 acabou de ser lançada.',
      ],
    },
    links: {
      name: [
        'Baixe Anki para macOS 10.14.4+ (Intel, 2.1.63 Qt6)',
        'Baixe Anki para macOS (Apple Silicon, 2.1.63 Qt6)',
        'Baixe Anki para macOS 10.13.4+ (Intel, 2.1.63 Qt5)',
        'Baixe Anki para macOS 10.14.4+ (Intel, 2.1.64 Qt6)',
        'Baixe Anki para macOS (Apple Silicon, 2.1.64 Qt6)',
        'Baixe Anki para macOS 10.13.4+ (Intel, 2.1.64 Qt5)',
        'Installation & troubleshooting guide',
        'Change notes & older releases',
        'Alternate download site',
        'Alternate changes site',
      ],
      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-intel-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-apple-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-mac-intel-qt5.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-intel-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-apple-qt6.dmg',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-mac-intel-qt5.dmg',
        'https://docs.ankiweb.net/platform/mac/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requerimentos',
      items: [
        'As versões recentes do Anki requerem um Mac executando o macOS 10.13.4 ou posterior.',
        'A última versão do Anki com suporte para macOS 10.10 a 10.13.3 foi 2.1.35-alternate. Se você estiver em uma máquina antiga, poderá obter a versão antiga na',
      ],
      links: ['página de versões'],
    },
    installing: {
      title: 'Instalação',
      items: [
        'Para instalar Anki:',
        'Baixe de',
        'Consulte a próxima seção para saber como escolher entre -qt5 e -qt6.',
        'Salve o instalador na área de trabalho ou na pasta de downloads.',
        'Abra-o e arraste o Anki para a pasta Aplicativos ou para a área de trabalho.',
        'Clique duas vezes em Anki no local em que você o colocou.',
      ],
      links: [],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'As versões recentes do Anki vêm em variantes Qt5 e Qt6 separadas. A versão Qt6 é recomendada para a maioria dos usuários.',
        'Vantagens da versão Qt6:',
        'Suporte nativo para Apple Silicon Macs recentes (mais rápido, melhor duração da bateria).',
        'Várias correções de bugs, incluindo coisas como: melhor suporte para idiomas menos comuns.',
        'Atualizações de segurança. O suporte para a biblioteca Qt5 foi descontinuado em novembro de 2020, o que significa que qualquer falhas de segurança descobertas desde então permanecerão sem correção.',
        'Desvantagens da versão Qt6:',
        'Janelas com guias (por exemplo, em tela cheia) não são mais possíveis.',
        'Alguns complementos atualmente funcionam apenas com a versão Qt5.',
      ],
      links: [],
    },
    upgrading: {
      title: 'Atualizando',
      items: [
        'Para atualizar, feche o Anki, se estiver aberto, e siga as etapas acima. Arraste o ícone Anki para o mesmo local onde você o armazenou anteriormente e, quando solicitado, substitua a versão antiga. Os dados dos seus baralhos serão preservados.',
      ],
      links: [],
    },
    addon: {
      title: 'Compatibilidade de Complementoss',
      items: [
        'Alguns complementos ainda não foram atualizados para oferecer suporte à versão mais recente do Anki. Se um complemento do qual você depende ainda não foi portado para a versão mais recente, você pode ter mais sorte com a versão 2.1.44 na',
      ],
      links: ['página de versões'],
    },
  },
};
