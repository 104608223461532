import { footer } from "./footer/Footer";
import { home } from "./home/Home";
import { navbar } from "./navbar/Navbar";

export const english = {
  SEO: {
    lang: "en",
    title: "Anki - the Best Flashcard program using Spaced Repetition",
    description:
      "Anki is a program which makes remembering things easy. Because it's a lot more efficient than traditional study methods",
  },
  navbar: navbar.en,
  home: {
    home: home.en.section1,
    homeRemember: home.en.section2,
    homeText: home.en.section3.about,
    homeFeatures: home.en.section3.features,
    homeBlock: home.en.section3.block,
    homeDownload: home.en.section4,
  },
  footer: footer.en,
};
