import styles from "./Footer.module.css";

type Props = {
  footer: {
    name: string[];
    link: string[];
    text: string[];
    button: string[];
  };
};

const Footer = ({ footer }: Props) => {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.support}>
          <h2>{footer.text[0]}</h2>
          <p>
            <button>
              <a href="/decks">{footer.button[0]}</a>
            </button>
          </p>
        </div>
        <div className={styles.support}>
          <h2>{footer.text[1]}</h2>
          <p>
            <button>
              <a href="https://forums.ankiweb.net/">{footer.button[1]}</a>
            </button>
          </p>
        </div>
        <div className={styles.support}>
          <img width="100px" height="100px" loading="lazy" src="../footer-logo.webp" alt="anki footer logo" />
        </div>
      </div>
      <div className={styles.footerbar}>
        <ul>
          {footer.name.map((name, index) => (
            <li key={index}>
              <a href={footer.link[index]}>{name}</a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
