export const dataDecks = {
  decks: [
    {
      id: 1,
      url: '4000-essential-english-words',
      title: '4000 Essential English Words (all books) - [2n edition] - Standard',
      description:
        '4000 Essential English Words is a six-book series that is designed to focus on practical high-frequency words to enhance the vocabulary of learners from high beginning to advance levels. ',
      notes: 3871,
      imgs: ['../assets/id-1/front.png', '../assets/id-1/back.png'],
      moreImgs: [
        '../assets/id-1/front2.png',
        '../assets/id-1/back2.png',
        '../assets/id-1/front3.png',
        '../assets/id-1/back3.png',
      ],
      details: [
        <p>
          4000 Essential English Words is a six-book series that is designed to focus on practical
          high-frequency words to enhance the vocabulary of learners from high beginning to advance levels.
          The series presents a variety of words that cover a large percentage of the words that can be found
          in many spoken or written texts. Thus, after mastering these target words, learners will be able to
          fully understand vocabulary items when they encounter them in written and spoken form. Each unit
          presents 20 words which are defined and used in sample sentences.{' '}
        </p>,
        <p>
          The activities in the books are designed to present the words in different uses so that learners can
          fully see how they can be utilized. Also at the end of each unit there is a story whch contains the
          unit's target words to give learners further examples of the words in use. Each level properly
          prepares the learner for the next which progressively challenges the learner with more sophisticated
          vocabulary and stories. Key features include: (1) Clear, easy to understand definitions and
          examples; (2) Various activities to reinforce target vocabulary; (3) Progressive development of
          vocabulary across levels; (4) Original stories which utilize target words; (5) Useful color images
          that illustrate each target word; and (6) Free downloadable supplemental audio recordings of target
          word lists and stories to aid in listening and pronunciation.
        </p>,
        <a
          href="https://drive.google.com/drive/folders/1JTL4RpI3a-0H4cBoXWF7u80BtfPYgm55?usp=sharing"
          className="btn-data"
        >
          DOWNLOAD
        </a>,
        <p>
          The 600 words in each book of this series along with the additional target words presented in the
          appendices included in the first three books of the series are the most useful words in English.
          They were found by analysis of a collection of English course books from various levels in the
          primary, secondary and tertiary school systems. The words included in this series were chosen
          because they occurred many times in different levels of these materials. Because of the way that
          they were chosen, these words have the following characteristics:
        </p>,
        <ol>
          <li>
            They are useful in both spoken and written English. No matter what English course you are
            studying, the words in these books will be of value to you.
          </li>
          <li>
            Each word in these books is a high-frequency word. This means that the effort in learning the
            words is well repaid by the number of times learners have a chance to encounter or use them.
          </li>
          <li>
            These books as a whole cover a large proportion of the words in any spoken or written text. They
            cover at least 80% of the words in newspapers and academic texts, and at least 90% of the words in
            novels. They also cover at least 90% of the words in conversation.
          </li>
        </ol>,
        <p>
          The activities in "4000 Essential English Words" are specially designed to make use of important
          learning conditions. Firstly, the words are introduced using sentence definitions and an example
          sentence. The activities that follow in the units encourage learners to recall the meanings and
          forms of the words. Some activities also make the learners think about the meaning of the words in
          the context of a sentence—a sentence different from the sentences that occurred in the introduction
          of the words. Moreover, each unit ends with a story containing the target words. While reading the
          story, the learners have to recall the meanings of the words and suit them to the context of the
          story. Such activities help learners develop a better understanding of a common meaning for a given
          word which fits the different uses.
        </p>,
        <p>
          Illustrations for each target word are provided to help learners visualize the word as it is being
          used in the example sentence. These word/image associations aim to help students grasp the meaning
          of the word as well as recall the word later. It should be noted that words have more than one
          grammatical category. However, this series focuses on the word’s most common form. This is mentioned
          to remind learners that just because a word is labeled and utilized as a noun in this series does
          not mean that it can never be used in another form such as an adjective. This series has simply
          focused on the word in the form that it is most likely to be expressed.{' '}
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1JTL4RpI3a-0H4cBoXWF7u80BtfPYgm55?usp=sharing',
    },
    {
      id: 2,
      url: '4000-essential-english-words-type-answer',
      title: '4000 Essential English Words (all books) - [2n edition] - Type Answer',
      description:
        '4000 Essential English Words is a six-book series that is designed to focus on practical high-frequency words to enhance the vocabulary of learners from high beginning to advance levels.',
      notes: 3600,
      imgs: ['../assets/id-2/front.png', '../assets/id-2/back.png'],
      moreImgs: [
        '../assets/id-2/front2.png',
        '../assets/id-2/back2.png',
        '../assets/id-2/front3.png',
        '../assets/id-2/back3.png',
      ],
      details: [
        <p>
          4000 Essential English Words is a six-book series that is designed to focus on practical
          high-frequency words to enhance the vocabulary of learners from high beginning to advance levels.
          The series presents a variety of words that cover a large percentage of the words that can be found
          in many spoken or written texts. Thus, after mastering these target words, learners will be able to
          fully understand vocabulary items when they encounter them in written and spoken form. Each unit
          presents 20 words which are defined and used in sample sentences.{' '}
        </p>,
        <p>
          The activities in the books are designed to present the words in different uses so that learners can
          fully see how they can be utilized. Also at the end of each unit there is a story whch contains the
          unit's target words to give learners further examples of the words in use. Each level properly
          prepares the learner for the next which progressively challenges the learner with more sophisticated
          vocabulary and stories. Key features include: (1) Clear, easy to understand definitions and
          examples; (2) Various activities to reinforce target vocabulary; (3) Progressive development of
          vocabulary across levels; (4) Original stories which utilize target words; (5) Useful color images
          that illustrate each target word; and (6) Free downloadable supplemental audio recordings of target
          word lists and stories to aid in listening and pronunciation.
        </p>,
        <a
          href="https://drive.google.com/drive/folders/1JTL4RpI3a-0H4cBoXWF7u80BtfPYgm55?usp=sharing"
          className="btn-data"
        >
          DOWNLOAD
        </a>,
        <p>
          The 600 words in each book of this series along with the additional target words presented in the
          appendices included in the first three books of the series are the most useful words in English.
          They were found by analysis of a collection of English course books from various levels in the
          primary, secondary and tertiary school systems. The words included in this series were chosen
          because they occurred many times in different levels of these materials. Because of the way that
          they were chosen, these words have the following characteristics:
        </p>,
        <ol>
          <li>
            They are useful in both spoken and written English. No matter what English course you are
            studying, the words in these books will be of value to you.
          </li>
          <li>
            Each word in these books is a high-frequency word. This means that the effort in learning the
            words is well repaid by the number of times learners have a chance to encounter or use them.
          </li>
          <li>
            These books as a whole cover a large proportion of the words in any spoken or written text. They
            cover at least 80% of the words in newspapers and academic texts, and at least 90% of the words in
            novels. They also cover at least 90% of the words in conversation.
          </li>
        </ol>,
        <p>
          The activities in "4000 Essential English Words" are specially designed to make use of important
          learning conditions. Firstly, the words are introduced using sentence definitions and an example
          sentence. The activities that follow in the units encourage learners to recall the meanings and
          forms of the words. Some activities also make the learners think about the meaning of the words in
          the context of a sentence—a sentence different from the sentences that occurred in the introduction
          of the words. Moreover, each unit ends with a story containing the target words. While reading the
          story, the learners have to recall the meanings of the words and suit them to the context of the
          story. Such activities help learners develop a better understanding of a common meaning for a given
          word which fits the different uses.
        </p>,
        <p>
          Illustrations for each target word are provided to help learners visualize the word as it is being
          used in the example sentence. These word/image associations aim to help students grasp the meaning
          of the word as well as recall the word later. It should be noted that words have more than one
          grammatical category. However, this series focuses on the word’s most common form. This is mentioned
          to remind learners that just because a word is labeled and utilized as a noun in this series does
          not mean that it can never be used in another form such as an adjective. This series has simply
          focused on the word in the form that it is most likely to be expressed.{' '}
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1f8XfRvmrmtUWPJqsRYYOPrkKRt4jXm6j?usp=sharing',
    },
    {
      id: 3,
      url: '1000-basic-english-words-type-answer',
      title: '1000 Basic English Words - Type Answer',
      description:
        '1000 Basic English Words is a four-level series designed to enhance the vocabulary of beginner level learners.',
      notes: 960,
      imgs: ['../assets/id-3/front.png', '../assets/id-3/back.png'],
      moreImgs: [
        '../assets/id-3/front2.png',
        '../assets/id-3/back2.png',
        '../assets/id-3/front3.png',
        '../assets/id-3/back3.png',
        '../assets/id-3/front4.png',
        '../assets/id-3/back4.png',
      ],
      details: [
        <p>
          1000 Basic English Words is a four-level series designed to enhance the vocabulary of beginner level
          learners. All of the words presented over the four books of the series provide learners with over
          1,000 practical, high-frequency English words. All of the words chosen for the series come from an
          analysis of the most commonly found words in textbooks and materials for English language learners.
          1000 Basic English Words provides an excellent foundation for EFL learners wanting to build a strong
          vocabulary.
        </p>,
        <h3>Key Features</h3>,
        <ul>
          <li>Appealing photographs that illustrate each target word</li>
          <li>Clear, easy to understand definitions and examples for all target words </li>
          <li>Original fiction and non-fiction passages using target words in context </li>
          <li>Correct pronunciation of target words indicated with phonetic symbols </li>
          <li>
            Audio recordings, review quizzes, worksheets, and answer keys available at compasspub.com/1000BEW
          </li>
        </ul>,
      ],
      download: 'https://drive.google.com/drive/folders/1wtwsFyXx8N3Hi7lHyjOGz2tqovRCEZAf?usp=sharing',
    },
    {
      id: 4,
      url: 'english-grammar-in-use-activities',
      title: 'English Grammar In Use - Activities',
      description:
        "This deck contains exercises from English Grammar in Use (4th Edition) CD-ROM. English Grammar in Use Fourth edition is an updated version of the world's best-selling grammar title. ",
      notes: 3606,
      imgs: ['../assets/id-4/front.png', '../assets/id-4/back.png'],
      moreImgs: [
        '../assets/id-4/extra1.png',
        '../assets/id-4/extra2.png',
        '../assets/id-4/extra3.png',
        '../assets/id-4/extra4.png',
        '../assets/id-4/extra5.png',
        '../assets/id-4/extra6.png',
      ],
      details: [
        <p>
          English Grammar in Use Fourth edition is an updated version of the world's best-selling grammar
          title. It has a fresh, appealing new design and clear layout, with revised and updated examples, but
          retains all the key features of clarity and accessibility that have made the book popular with
          millions of learners and teachers around the world. The CD-ROM contains lots of additional practice
          exercises to consolidate learning, ideal for self-study but also suitable for reinforcement work in
          the classroom. An online version and book without answers are available separately.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1JQ4KodkSF9kUSZre1Yoh5bFsHzBVZy8S?usp=sharing',
    },
    {
      id: 5,
      url: 'english-grammar-in-use-exercises',
      title: 'English Grammar In Use - Exercises',
      description:
        "This deck contains exercises from the English Grammar in Use app. English Grammar in Use Fourth edition is an updated version of the world's best-selling grammar title.",
      notes: 5885,
      imgs: ['../assets/id-5/front.png', '../assets/id-5/back.png'],
      moreImgs: [
        '../assets/id-5/extra1.png',
        '../assets/id-5/extra2.png',
        '../assets/id-5/extra3.png',
        '../assets/id-5/extra4.png',
        '../assets/id-5/extra5.png',
        '../assets/id-5/extra6.png',
      ],
      details: [
        <p>
          This deck contains exercises from{' '}
          <a
            href="http://www.cambridge.org/us/cambridgeenglish/catalog/grammar-vocabulary-and-pronunciation/english-grammar-use-4th-edition/product-details/app"
            rel="nofollow"
          >
            English Grammar in Use
          </a>{' '}
          App
        </p>,
        <p>
          The world's best-selling grammar series for learners of English. Raymond Murphy's classic reference
          and practice book for learners of English at intermediate (B1-B2) level. Perfect for self-study, but
          also ideal for supplementary grammar activities in the classroom. This book has been used by
          millions of language learners and teachers around the world.
        </p>,
        <h3>Key Features</h3>,

        <ul>
          <li>
            Arranged in a tried-and-trusted, easy to use format, with explanations of grammar points on each
            left-hand page and exercises to check understanding on the right
          </li>
          <li>Lots of additional practice exercises to consolidate learning </li>
          <li>Fresh, appealing new design and clear layout, with revised and updated examples </li>
        </ul>,
      ],
      download: 'https://drive.google.com/drive/folders/1tHsBz1eHTDfHfhnNuA6zWRMDgvbVTzTs?usp=sharing',
    },
    {
      id: 6,
      url: 'advanced-english-grammar-in-use-activities',
      title: 'Advanced English Grammar In Use - Activities',
      description:
        'Advanced Grammar In Use is a reference and practice book for learners of English at advanced (C1-C2) levels',
      notes: 2500,
      imgs: ['../assets/id-6/front.png', '../assets/id-6/back.png'],
      moreImgs: [
        '../assets/id-6/extra1.png',
        '../assets/id-6/extra2.png',
        '../assets/id-6/extra3.png',
        '../assets/id-6/extra4.png',
        '../assets/id-6/extra5.png',
        '../assets/id-6/extra6.png',
      ],
      details: [
        <p>
          The world's best-selling grammar series for learners of English. This third edition, with answers,
          is ideal for self-study. The book contains 100 units of grammar reference and practice materials,
          with photos and illustrations in full colour and a user-friendly layout. It is ideal for learners
          preparing for the Cambridge Advanced, Proficiency or IELTS examinations, and is informed by the
          Cambridge International Corpus, which ensures the language is authentic and up-to-date. Versions
          without answers or with a CD-ROM are available to purchase separately.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1zLmyN2vgaMOe5DiZms6k9pcp3VCTRmr0?usp=sharing',
    },
    {
      id: 7,
      url: 'essential-idioms-in-english',
      title: 'Essential Idioms in English',
      description:
        'This widely-used classic book helps people learn and understand 500 of the most common English-language idioms.',
      notes: 468,
      imgs: ['../assets/id-7/front.png', '../assets/id-7/back.png'],
      moreImgs: ['../assets/id-7/extra1.png', '../assets/id-7/extra2.png'],
      details: [
        <p>
          From catching a cold to landing on your feet, idiomatic expressions add color and style to the
          English language. The latest edition of Essential Idioms in English remains the resource of choice
          for mastering more than 500 common English idioms, phrasal verbs, and collocations. Essential Idioms
          in English thoroughly defines and illustrates each idiom, then reinforces its meaning and usage with
          multiple-choice, true-false, fill-in-the-blank, and matching exercises.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1v9uYASh8EiguE6ro-zXXum3MkYOvV6-9?usp=sharing',
    },
    {
      id: 8,
      url: 'basic-essential-intermediate-advanced-grammar-in-use-by-cambridge',
      title: 'Basic, Essential, Intermediate, Advanced Grammar in Use by Cambridge',
      description:
        'Comprehensive, easy to use and flexible, the grammar reference and practice books cover all levels from elementary to advanced.',
      notes: 2230,
      imgs: ['../assets/id-8/front.png', '../assets/id-8/back.png'],
      moreImgs: [
        '../assets/id-8/extra1.png',
        '../assets/id-8/extra2.png',
        '../assets/id-8/extra3.png',
        '../assets/id-8/extra4.png',
      ],
      details: [
        <p>
          By choosing in Use, you will have more language learning at your fingertips than ever before. The
          interactive ebooks make it easy to study on the go: no matter where you are, you can listen to audio
          and access all of the content found in the printed books. Of course, the format is still as simple
          as ever: clear explanations on each left-hand page and lots of exercises to check understanding on
          the right.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1J30QC0aaVQt04y8ITCWaDfGkxgZ5JrC4?usp=sharing',
    },
    {
      id: 9,
      url: 'business-essential-intermediate-advanced-vocabulary-in-use-by-cambridge',
      title: 'Business, Basic, Essential, Intermediate, Advanced, Vocabulary in Use by Cambridge',
      description:
        'Comprehensive, easy to use and flexible, the grammar reference and practice books cover all levels from elementary to advanced.',
      notes: 1547,
      imgs: ['../assets/id-9/front.png', '../assets/id-9/back.png'],
      moreImgs: [
        '../assets/id-9/extra1.png',
        '../assets/id-9/extra2.png',
        '../assets/id-9/extra3.png',
        '../assets/id-9/extra4.png',
      ],
      details: [
        <p>
          By choosing in Use, you will have more language learning at your fingertips than ever before. The
          interactive ebooks make it easy to study on the go: no matter where you are, you can listen to audio
          and access all of the content found in the printed books. Of course, the format is still as simple
          as ever: clear explanations on each left-hand page and lots of exercises to check understanding on
          the right.
        </p>,
        <p>
          The Vocabulary series gives you the words you need to communicate with confidence. Whether the focus
          is on general vocabulary, business vocabulary or on specific areas such as collocations, idioms and
          phrasal verbs, there is an in Use to meet your needs.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1xmALqjPzeCgHspRVKp5_BrUlF3ohQawV?usp=sharing',
    },
    {
      id: 10,
      url: 'cambridge-english-idioms-in-use',
      title: 'Cambridge Grammar of English',
      description:
        'A major reference grammar offering comprehensive coverage of spoken and written English based on real everyday usage.',
      notes: 1530,
      imgs: ['../assets/id-10/front.png', '../assets/id-10/back.png'],
      moreImgs: [
        '../assets/id-10/extra1.png',
        '../assets/id-10/extra2.png',
        '../assets/id-10/extra3.png',
        '../assets/id-10/extra4.png',
      ],
      details: [
        <p>
          This book presents a new and comprehensive descriptive grammar of English, written by the principal
          authors in collaboration with an international research team of a dozen linguists in five countries.
          It represents a major advance over previous grammars by virtue of drawing systematically on the
          linguistic research carried out on English during the last forty years. It incorporates insights
          from the theoretical literature but presents them in a way that is accessible to readers without
          formal training in linguistics. It is based on a sounder and more consistent descriptive framework
          than previous large-scale grammars, and includes much more explanation of grammatical terms and
          concepts, together with justification for the ways in which the analysis differs from traditional
          grammar. The book contains twenty chapters and a guide to further reading. Its usefulness is
          enhanced by diagrams of sentence structure, cross-references between sections, a comprehensive
          index, and user-friendly design and typography throughout.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1y0YKYy_flLwG3Knye9qPxlhts5oNUCKQ?usp=sharing',
    },
    {
      id: 11,
      url: 'other-anki-decks',
      title: 'Other ANKI Decks',
      description: 'Collection of decks to learn english. American, British, Grammar, Dictionary, etc.',
      notes: 99999,
      imgs: ['../assets/id-11/front.png', '../assets/id-11/back.png'],
      moreImgs: ['../assets/id-11/extra1.png', '../assets/id-11/extra2.png'],
      details: [
        <p>
          From catching a cold to landing on your feet, idiomatic expressions add color and style to the
          English language. The latest edition of Essential Idioms in English remains the resource of choice
          for mastering more than 500 common English idioms, phrasal verbs, and collocations. Essential Idioms
          in English thoroughly defines and illustrates each idiom, then reinforces its meaning and usage with
          multiple-choice, true-false, fill-in-the-blank, and matching exercises.
        </p>,
      ],
      download: 'https://drive.google.com/drive/folders/1BUjcCzP0P9qu4xirQuvcAnwBsp6gU6Pk?usp=sharing',
    },
  ],
};
