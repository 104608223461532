export const linux = {
  en: {
    download: {
      title: 'Download',
      items: ['2.1.63 was the previous release. Two variants are available:', '2.1.64 was just released.'],
    },
    links: {
      name: [
        'Download Anki for Linux 2018+ (2.1.63 Qt6)',
        'Download Anki for Linux 2018+ (2.1.63 Qt5)',
        'Download Anki for Linux 2018+ (2.1.64 Qt6)',
        'Download Anki for Linux 2018+ (2.1.64 Qt5)',
        'Installation & troubleshooting guide',
        'Change notes & older releases',
        'Alternate download site',
        'Alternate changes site',
      ],
      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-linux-qt6.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-linux-qt5.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-linux-qt6.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-linux-qt5.tar.zst',
        'https://docs.ankiweb.net/platform/windows/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requirements',
      items: [
        'The packaged version requires a recent 64 bit Intel/AMD Linux with glibc, and common libraries like libwayland-client and systemd. If you are on a different architecture, or a barebones Linux distro, you will not be able to use the packaged version, but you may be able to use the',
        'On recent Ubuntu installations, please use the following before installing:',
        'sudo apt install libxcb-xinerama0',
        'If Anki fails to start after installing, you may be',
        "Anki's build system only supports glibc, so musl-based distros are not currently supported.",
      ],
      links: ['Python wheels', 'missing other libraries'],
    },
    installing: {
      title: 'Installating',
      items: [
        'To install Anki:',
        'Download Anki from',
        'to your Downloads folder. See the next section for how to choose between -qt5 and -qt6.',
        "If zstd is not already installed on your system, you'll need to install it",
        'Open a terminal and run the following commands, replacing the filename as appropriate.',
        'On some Linux systems, you may need to use',
        "You can then start Anki by typing 'anki' and hitting enter. If you encounter any issues, please see the links on the left.",
      ],
      codeItems: [
        '(eg sudo apt install zstd)',
        'tar xaf Downloads/anki-2.1.XX-linux-qt6.tar.zst cd anki-2.1.XX-linux-qt6 sudo ./install.sh',
        'tar xaf --use-compress-program=unzstd',
      ],
      links: [],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'Recent Anki versions come in separate Qt5 and Qt6 variants. The Qt6 version is recommended for most users.',
        'Advantages of the Qt6 version:',
        'Compatibility with recent glibc versions (fixes',
        'Better HiDPI support.',
        'Better Wayland support.',
        'Various bugfixes, including things like better support for less common languages.',
        'Security updates. Support for the Qt5 library was discontinued in Nov 2020, meaning that any security flaws discovered since then will remain unfixed.',
        'Disadvantages of the Qt6 version include:',
        'Some add-ons currently only work with the Qt5 version.',
      ],
      links: ['blank screens on recent distros'],
    },
    upgrading: {
      title: 'Upgrading',
      items: [
        'If you were running Anki from a .deb/.rpm/etc in the past, please make sure to remove the system version before installing the package provided here.',
        "If you're upgrading from a previous package, simply repeat the installation steps to upgrade to the latest version. Your user data will be preserved.",
        'If you wish to downgrade to a previous version, please make sure you',
      ],
      links: ['downgrade first'],
    },
    addon: {
      title: 'Add-on Compatibility',
      items: [
        'Some add-ons have not been updated to support the latest Anki release yet. If an add-on you depend on has not been ported to the latest release yet, you may have more luck with 2.1.44 from the',
      ],
      links: ['releases page'],
    },
  },
  pt: {
    download: {
      title: 'Download',
      items: [
        '2.1.63 foi a versão anterior. Duas variantes estão disponíveis:',
        '2.1.64 acabou de ser lançada.',
      ],
    },
    links: {
      name: [
        'Baixe Anki para Linux 2018+ (2.1.63 Qt6)',
        'Baixe Anki para Linux 2018+ (2.1.63 Qt5)',
        'Baixe Anki para Linux 2018+ (2.1.64 Qt6)',
        'Baixe Anki para Linux 2018+ (2.1.64 Qt5)',
        'Installation & troubleshooting guide',
        'Change notes & older releases',
        'Alternate download site',
        'Alternate changes site',
      ],
      link: [
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-linux-qt6.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.63/anki-2.1.63-linux-qt5.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-linux-qt6.tar.zst',
        'https://github.com/ankitects/anki/releases/download/2.1.64/anki-2.1.64-linux-qt5.tar.zst',
        'https://docs.ankiweb.net/platform/windows/installing.html',
        'https://github.com/ankitects/anki/releases',
        'https://apps.ankiweb.net/downloads/archive',
        'https://changes.ankiweb.net',
      ],
    },
    requirements: {
      title: 'Requerimentos',
      items: [
        'A versão em pacote requer um Linux Intel/AMD recente de 64 bits com glibc e bibliotecas comuns como libwayland-client e systemd. Se você estiver em uma arquitetura diferente ou em uma distribuição Linux básica, não poderá usar a versão empacotada, mas poderá usar',
        'Em instalações recentes do Ubuntu, use o seguinte comando antes de instalar:',
        'sudo apt install libxcb-xinerama0',
        'Se o Anki falhar ao iniciar após a instalação, outras bibliotecas',
        'O sistema de compilação do Anki suporta apenas glibc, então distros baseadas em musl não são suportadas atualmente.',
      ],
      links: ['Python Wheels', 'podem estar faltando'],
    },
    installing: {
      title: 'Instalação',
      items: [
        'Para instalar Anki:',
        'Baixe de',
        'Consulte a próxima seção para saber como escolher entre -qt5 e -qt6.',
        'Se o zstd ainda não estiver instalado em seu sistema, você precisará instalá-lo',
        'Abra um terminal e execute os seguintes comandos, substituindo o nome do arquivo conforme apropriado.',
        'Em alguns sistemas Linux, pode ser necessário usar',
        "Você pode iniciar o Anki digitando 'anki' e pressionando enter. Se você encontrar algum problema, por favor, veja o guia de solução de problemas.",
      ],
      codeItems: [
        '(isto é,sudo apt install zstd)',
        'tar xaf Downloads/anki-2.1.XX-linux-qt6.tar.zst cd anki-2.1.XX-linux-qt6 sudo ./install.sh',
        'tar xaf --use-compress-program=unzstd',
      ],
      links: [],
    },
    qt5vsqt6: {
      title: 'Qt5 vs Qt6',
      items: [
        'As versões recentes do Anki vêm em variantes Qt5 e Qt6 separadas. A versão Qt6 é recomendada para a maioria dos usuários.',
        'Vantagens da versão Qt6:',
        'Compatibilidade com versões glibc recentes (corrige',
        'Melhor suporte HiDPI.',
        'Melhor suporte para Wayland.',
        'Várias correções de bugs, incluindo coisas como: melhor suporte para idiomas menos comuns.',
        'Atualizações de segurança. O suporte para a biblioteca Qt5 foi descontinuado em novembro de 2020, o que significa que qualquer falhas de segurança descobertas desde então permanecerão sem correção.',
        'As desvantagens da versão Qt6 incluem:',
        'Alguns complementos atualmente funcionam apenas com a versão Qt5.',
      ],
      links: ['telas em branco em distros recentes'],
    },
    upgrading: {
      title: 'Atualizando',
      items: [
        'Se você estava executando o Anki a partir de um arquivo .deb/.rpm/etc no passado, certifique-se de remover a versão do sistema antes de instalar o pacote fornecido aqui.',
        'Se estiver atualizando de um pacote anterior, basta repetir as etapas de instalação para atualizar para a versão mais recente. Seus dados de usuário serão preservados.',
        'Se você deseja fazer o downgrade para uma versão anterior, certifique-se de fazer',
      ],
      links: ['downgrade primeiro'],
    },
    addon: {
      title: 'Compatibilidade de Complementoss',
      items: [
        'Alguns complementos ainda não foram atualizados para oferecer suporte à versão mais recente do Anki. Se um complemento do qual você depende ainda não foi portado para a versão mais recente, você pode ter mais sorte com a versão 2.1.44 na',
      ],
      links: ['página de versões'],
    },
  },
};
