import styles from "./HomeSection2.module.css";

export type homeRemember = {
  homeRemember: string[];
};

const HomeSection2 = ({ homeRemember }: homeRemember) => {
  return (
    <section className={styles.ankiLink}>
      <div className="hidden-phone">
        <hr className={styles.hr} />
        <div className={styles.container}>
          <p>
            <span>{homeRemember[0]}</span>
            {homeRemember[1]}
          </p>

          <p>
            <span>{homeRemember[2]}</span>
            {homeRemember[3]}
          </p>

          <p>
            <span>{homeRemember[4]}</span>
            {homeRemember[5]}
          </p>
        </div>
        <hr className={styles.hr} />
      </div>
      <h2>{homeRemember[6]}</h2>
      <p>
        {homeRemember[7]} <a href="https://apps.ankiweb.net">AnkiWeb</a>.
      </p>
    </section>
  );
};

export default HomeSection2;
