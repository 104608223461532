import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// paths
import Home from "./pages/home/Home";
import Decks from "./pages/decks/Decks";
import CardDetails from "./pages/decks/CardDetails";
// languages
import { portuguese } from "./data/language/Portuguese";
import { english } from "./data/language/English";

const root = ReactDOM.createRoot(document.getElementById("AnkiBrasil") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              SEO={portuguese.SEO}
              navbar={portuguese.navbar}
              home={portuguese.home}
              footer={portuguese.footer}
            />
          }
        />
        <Route
          path="/english/"
          element={
            <Home SEO={english.SEO} navbar={english.navbar} home={english.home} footer={english.footer} />
          }
        />
        <Route path="/decks/" element={<Decks />} />
        <Route path="decks/:deckId/" element={<CardDetails />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
