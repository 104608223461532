import styles from "./Navbar.module.css";
import { useState } from "react";

type Props = {
  navbar: {
    name: string[];
    link: string[];
  };
};

const Navbar = ({ navbar }: Props) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const showHideMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <nav className={styles.nav}>
      <img src="../logo.webp" width="210px" height="50px" alt="anki logo" />
      <button className={styles.icon} onClick={showHideMenu} title="menu">
        {/* icon from heroicons.com */}
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div className={isNavExpanded ? `${styles.menu} ${styles.expanded}` : styles.menu}>
        <ul>
          {navbar.name.map((name, index) => (
            <li key={index}>
              <a href={navbar.link[index]}>{name}</a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
