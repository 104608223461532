export const mobile = {
  en: {
    title: ["iPhone/iPad/iPod Touch", "Android Devices"],
    items: [
      "AnkiMobile is a paid companion to the free computer program, for use on Apple devices. By purchasing the app, you help to support Anki's future development. If you can't afford the app, you can still use",
      "AnkiDroid is written by a separate group of people. As it was based off the free desktop code I make available, the AnkiDroid developers decided to make the Android version free as well.",
    ],
    links: ["Get AnkiMobile", "Get AnkiDroid"],
  },
  pt: {
    title: ["iPhone/iPad/iPod Touch", "Dispositivos Android"],
    items: [
      "AnkiMobile é um complemento pago para o programa de computador gratuito, para uso em dispositivos Apple. Ao adquirir o aplicativo, você ajuda a apoiar o desenvolvimento futuro da Anki. Se você não puder pagar pelo aplicativo, ainda poderá usar a",
      "AnkiDroid é escrito por um grupo separado de pessoas. Como foi baseado no código de desktop gratuito, os desenvolvedores do AnkiDroid decidiram tornar a versão do Android gratuita também.",
    ],
    links: ["Obtenha AnkiMobile", "Obtenha AnkiDroid"],
  },
};
