import styles from "./HomeSection3.module.css";

type Props = {
  about: string[];
  features: {
    title: string;
    itemsSpan: string[];
    items: string[];
  };
  block: {
    items: string[];
    links: string[];
  };
};

const HomeSection3 = ({ about, features, block }: Props) => {
  return (
    <section className={styles.container}>
      <div>
        <h2>{about[0]}</h2>
        <p>{about[1]}</p>
        <p>{about[2]}</p>
        <ul>
          <li>{about[3]}</li>
          <li>{about[4]}</li>
          <li>{about[5]}</li>
          <li>{about[6]}</li>
          <li>{about[7]}</li>
          <li>{about[8]}</li>
        </ul>
        <div>
          <h2>{features.title}</h2>
          <div className={styles.containerFeatures}>
            <div className={styles.features}>
              <p>
                <span>{features.itemsSpan[0]}</span>
                {features.items[0]}
              </p>

              <p>
                <span>{features.itemsSpan[1]}</span>
                {features.items[1]}
              </p>

              <p>
                <span>{features.itemsSpan[1]}</span>
                {features.items[1]}
              </p>
            </div>
            <div className={styles.features}>
              <p>
                <span>{features.itemsSpan[2]}</span>
                {features.items[2]}
              </p>

              <p>
                <span>{features.itemsSpan[3]}</span>
                {features.items[3]}
              </p>
              <p>
                <span>{features.itemsSpan[4]}</span>
                {features.items[4]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar}>
        <blockquote>
          <p>
            {block.items[0]} <i>{block.items[1]}</i>
          </p>
          <small>
            <a href={block.links[0]} target="_blank" rel="noreferrer">
              {block.items[2]}
            </a>
          </small>
        </blockquote>
        <blockquote>
          <p>{block.items[3]} </p>
          <small>
            <a href={block.links[1]} target="_blank" rel="noreferrer">
              {block.items[4]}
            </a>
          </small>
        </blockquote>
        <blockquote>
          <p>{block.items[5]}</p>
          <small>
            <a href={block.links[2]} target="_blank" rel="noreferrer">
              {block.items[6]}
            </a>
          </small>
        </blockquote>
      </div>
    </section>
  );
};

export default HomeSection3;
